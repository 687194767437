<template>
  <div>
    <CRow>
      <CCol sm="6" lg="3">
        <CWidgetDropdown
          color="primary"
          :header="number.sale"
          text="Ejecuciones de venta del día"
        >
          <template #default>
            <CIcon name="cilDollar" />
          </template>
          <template #footer>
            <CIcon name="" />
          </template>
        </CWidgetDropdown>
      </CCol>
      <CCol sm="6" lg="3">
        <CWidgetDropdown
          color="info"
          :header="number.quota"
          text="Cotizaciones realizadas en el día"
        >
          <template #default>
            <CIcon name="cilTask" />
          </template>
          <template #footer>
            <CIcon name="" />
          </template>
        </CWidgetDropdown>
      </CCol>
      <CCol sm="6" lg="3">
        <CWidgetDropdown
          color="warning"
          :header="number.visit"
          text="visitas registradas en el día"
        >
          <template #default>
            <CIcon name="cilCalendar" />
          </template>
          <template #footer>
            <CIcon name="" />
          </template>
        </CWidgetDropdown>
      </CCol>
      <CCol sm="6" lg="3">
        <CWidgetDropdown
          color="danger"
          :header="number.project"
          text="Número de proyectos"
        >
          <template #default>
            <CIcon name="cil-location-pin" />
          </template>
          <template #footer>
            <CIcon name="" />
          </template>
        </CWidgetDropdown>
      </CCol>
    </CRow>
    <CRow>
      <CCol sm="12" lg="12" md="12">
        <CCard>
          <CCardHeader class="card-visit">
            <label class="visit">
              Visitas programadas para hoy {{ item.date }}: {{ count.prog }}
            </label>
          </CCardHeader>
          <table class="table table-striped table-bordered table-responsive-sm">
            <thead class="table-success">
              <tr>
                <th>Hora Salida</th>
                <th>Cliente</th>
                <th>Asesor</th>
                <th>Proyecto</th>
                <th>Estado</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in body_table" :key="index">
                <td>{{ item.hour }}</td>
                <td>{{ item.client }}</td>
                <td>{{ item.user }}</td>
                <td>{{ item.project }}</td>
                <td v-if="item.state == 1">
                  <span class="badge float-right badge-warning"
                    >Registrado</span
                  >
                </td>
                <td v-if="item.state == 2">
                  <span class="badge float-right badge-success">Aceptado</span>
                </td>
                <td v-if="item.state == 3">
                  <span class="badge float-right badge-danger">Cancelado</span>
                </td>
              </tr>

              <tr v-if="!body_table.length">
                <td :colspan="5">
                  <h3>No hay datos para mostrar</h3>
                </td>
              </tr>
            </tbody>
          </table>
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import { CChartLineSimple, CChartBarSimple } from "../charts/index.js";

import { mapActions } from "vuex";
export default {
  name: "WidgetsDropdown",
  components: { CChartLineSimple, CChartBarSimple },
  data() {
    return {
      body_table: [],
      item: [],
      count: [],
      number: {
        visit: "0",
        quota: "0",
        sale: "0",
        project: "0",
      },
    };
  },
  created() {
    this.mtd_getdata();
  },
  methods: {
    ...mapActions(["get", "post"]),
    mtd_getdata() {
      this.post({
        url: this.$store.getters.get__url + "/home",
        token: this.$store.getters.get__token,
      })
        .then((response) => {
          this.body_table = response.data;
          this.item.date = response.date;
          this.count.prog = response.prog;
          this.number.visit = response.visit.toString();
          this.number.sale = response.sale.toString();
          this.number.quota = response.quot.toString();
          this.number.project = response.project.toString();
        })
        .catch((errors) => {});
    },
  },
};
</script>
<style lang="scss" scoped>
@import url("https://fonts.googleapis.com/css2?family=Roboto+Mono:ital,wght@0,600;1,600&display=swap");
.visit {
  font-size: 1rem;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: bold;
  color: white;
}
.card-visit {
  background-color: #4db6ac;
}
</style>
